import Vue from 'vue';
import VueRouter from 'vue-router'; // 引入vue-router
import Home from '@/components/Home.vue'; // 路由懒加载
import DeletingData from '@/components/DeletingData.vue'; // 路由懒加载
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'; // 路由懒加载

Vue.use(VueRouter); // 在Vue中注册VueRouter

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/DeletingData',
    name: 'DeletingData',
    component: DeletingData,
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: 'history', // 路由模式
  routes,
});

export default router; //导出router

<template>
  <div>
    <transition name="slide-fade" @after-enter="hideElement">
      <div class="success" v-if="showSuccess">
        <img src="@/assets/cg.png" alt="" />
        <div>
          <div>Eliminado con éxito</div>
          <div>esperamos verlo pronto!</div>
        </div>
      </div>
    </transition>
    <div class="remove">
      <div class="remove-desc">
        <img src="@/assets/kong.png" alt="" />
        <p>Por qué desea dejarnos?</p>
        <span
          >Si tiene algún problema, contáctenos y haremos todo lo posible para
          solucionarlo. Déjenos una oportunidad más!</span
        >
      </div>
      <div class="remove-action">
        <p>
          Antes de cerrar la cuenta, por favor, lea atentamente las siguientes
          advertencias importantes. Después de cerrar la cuenta, no podrá
          utilizarla, incluyendo, pero no limitado a: (1) No podrá iniciar
          sesión ni usar esta cuenta nuevamente. La imagen de perfil de la
          cuenta se restablecerá a la predeterminada y el apodo se restablecerá
          a "Usuario cerrado". (2) La información personal y el historial de
          esta cuenta serán irreversiblemente eliminados. (3) La información de
          autenticación de esta cuenta será borrada.
        </p>
        <span>Introduzca su número de teléfono móvil</span>
        <div class="num">
          <span>+52</span>
          <input
            class="phone"
            v-model="phone"
            type="number"
            oninput="if(value.length>10)value=value.slice(0,10)"
            placeholder="Por favor ingrese el número de teléfono móvil de 10 dígitos"
          />
        </div>
        <div
          class="btn"
          :class="phone.length === 10 ? 'btn-a' : ''"
          @click="Confirmar"
        >
          Confirmar cierre de cuenta
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      phone: '',
      showSuccess: false,
    };
  },
  methods: {
    Confirmar() {
      if (this.phone.length === 10) {
        this.showSuccess = true;
        this.phone = '';
      }
    },
    hideElement() {
      setTimeout(() => {
        this.showSuccess = false;
      }, 3000);
    },
  },
};
</script>

<style>
.remove {
  padding: 96px 360px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.remove-desc {
  width: 480px;
}

.remove-desc img {
  width: 400px;
  height: 240px;
  margin-bottom: 40px;
}
.remove-desc p {
  font-weight: 600;
  font-size: 32px;
  color: #fa7d32;
  margin-bottom: 15px;
}
.remove-desc span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #565656;
  line-height: 28px;
}
.remove-action {
  width: 560px;
  background: #ffffff;
  opacity: 0.8;
  padding: 40px;
  box-sizing: border-box;
}
.remove-action p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #565656;
  line-height: 20px;
  margin-bottom: 40px;
}

.remove-action span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #565656;
  line-height: 22px;
}
.remove-action .num {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #565656;
  line-height: 22px;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
}
.btn {
  margin-top: 42px;
  width: 480px;
  height: 74px;
  line-height: 74px;
  text-align: center;
  background: #c8c8c8;
  border-radius: 37px;
  border: 1px solid #c8c8c8;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}
.btn-a {
  background: linear-gradient(134deg, #fa9632 0%, #fa7d32 52%, #fa9632 100%);
  border-color: #fa9632;
}

.phone {
  margin-left: 10px;
  font-size: 16px;
  color: #565656;
  border: none;
  outline: none;
  width: 90%;
}
/* 隐藏输入框上下箭头 */
.phone::-webkit-inner-spin-button,
.phone::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 兼容Firefox */
.phone[type='number'] {
  -moz-appearance: textfield;
}

.success {
  width: 320px;
  height: 64px;
  background: #ffffff;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}
.success img {
  width: 48px;
  height: 44px;
  margin-right: 20px;
}

.success div div:first-child {
  font-weight: 500;
  font-size: 14px;
  color: #787878;
  margin-bottom: 5px;
}
.success div div:last-child {
  font-weight: 500;
  font-size: 18px;
  color: #fa7e32;
}

.slide-fade-enter-active {
  transition: opacity 1s;
}
.slide-fade-enter {
  opacity: 0;
}
</style>

<template>
  <div id="app">
    <header class="title">
      <img src="@/assets/logo.png" alt="" />
      <ul class="menu-list">
        <li
          v-for="(item, index) in menu"
          :key="index"
          :class="routeIndex === index ? 'menu-active' : ''"
          @click="menuChange(index, item.router)"
        >
          {{ item.title }}
        </li>
      </ul>
    </header>

    <RouterView></RouterView>

    <footer class="footer-2">
      <img src="@/assets/logo.png" alt="" />
      <div>
        <p>Tel: +525560877522</p>
        <p>Nombre de empresa: Alcamer Int Comercializadora, S.A.S. de C.V.</p>
        <p>Correo: servicio@alcamerintcomercial.com</p>
        <p>
          Domicilio: Antonio Sola No. 40 Condesa, Cuauhtémoc México, 06140
          Mexico
        </p>
      </div>
      <p @click="menuChange(2, '/PrivacyPolicy')">Acuerdo de privacidad</p>
    </footer>
    <div class="footerHeight">
      <footer class="footer-1">
        <img src="@/assets/logo2.png" alt="" />
        <div>
          <p>CashMXN</p>
          <span
            >En cualquier momento y lugar, aquí estamos cuando nos
            necesite</span
          >
        </div>
        <img style="margin-left: auto" src="@/assets/xiazai.png" alt="" />
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      routeIndex: 0,
      menu: [
        {
          title: 'PÁGINA DE INICIO',
          router: '/',
        },
        {
          title: 'CERRAR CUENTA',
          router: '/DeletingData',
        },
      ],
    };
  },
  watch: {
    $route: {
      handler: function (to) {
        localStorage.setItem('routeIndex', to.path);
      },
    },
  },
  created() {
    const path = localStorage.getItem('routeIndex');
    if (path === '/') {
      this.routeIndex = 0;
    } else if (path === '/DeletingData') {
      this.routeIndex = 1;
    } else {
      this.routeIndex = 2;
    }
  },
  methods: {
    menuChange(index, name) {
      this.routeIndex = index;
      this.$router.push({ path: name });
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    },
  },
};
</script>

<style>
.title {
  height: 64px;
  line-height: 64px;
  padding: 0 360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  font-size: 20px;
  position: sticky;
  top: 0;
  z-index: 9;
}

.title img {
  width: 96px;
  height: 40px;
}

.menu-list {
  display: flex;
  align-items: center;
  gap: 86px;
  font-size: 22px;
  color: #565656;
}
.menu-active {
  color: #fa7d32;
}

.menu-list li {
  cursor: pointer;
}

.footer-1 {
  background-color: #ffffff;
  padding: 30px 360px;
  display: flex;
  align-items: center;
  box-shadow: 0px -8px 16px 0px rgba(250, 100, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.footer-1 img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  cursor: pointer;
}
.footer-1 p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 32px;
  color: #565656;
  line-height: 45px;
}
.footer-1 span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #787878;
  line-height: 28px;
}
.footer-2 {
  background-color: #ffffff;
  padding: 40px 360px;
  display: flex;
  justify-content: space-between;
}
.footer-2 img {
  width: 96px;
  height: 40px;
}
.footer-2 div > p {
  font-weight: 500;
  font-size: 16px;
  color: #787878;
  margin-bottom: 5px;
}
.footer-2 > p {
  font-weight: 400;
  font-size: 20px;
  color: #0064fa;
  cursor: pointer;
}
.footerHeight {
  height: 160px;
}
</style>
